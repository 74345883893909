:root {
  --primary-main: #005587;
  --primary-white: #ffffff;
  --primary-gray-2: #fbfbfb;
  --primary-gray-3: #f8f8f8;
  --primary-gray-10: #eaecec;
  --primary-gray-15: #dfe2e3;
  --primary-gray-20: #f8f8f8;
  --primary-gray-25: #caced0;
  --primary-gray-40: #aab0b3;
  --primary-gray-50: #959ca0;
  --primary-gray-75: #606b71;
  --primary-gray-100: #2b3a41;
  --primary-fuchsia-50: #c080b1;
  --primary-fuchsia-75: #a0438b;
  --primary-fuchsia-100: #810964;
  --primary-dark-blue-50: #80aac3;
  --primary-dark-blue-70: #4C88AB;
  --primary-red-75: #e16058;
  --secondary-gray-3: #333333;
  --secondary-gray-4: #444444;
  --primary-dark-gray-100: #444444;
  --primary-dark-gray-50: #A1A1A1;
  --primary-orange-70: #FFB34C;
  --font: 'Inter';
  --line-height: 140%;

  --typography-h1: normal normal 600 30px / var(--line-height) var(--font);
  --typography-h2: normal normal 600 24px / var(--line-height) var(--font);
  --typography-h3: normal normal 600 22px / var(--line-height) var(--font);
  --typography-h4: normal normal 600 20px / var(--line-height) var(--font);
  --typography-h5: normal normal 600 18px / var(--line-height) var(--font);
  --typography-h6: normal normal 600 16px / var(--line-height) var(--font);

  --typography-pb1: normal normal 600 16px / var(--line-height) var(--font);
  --typography-pb2: normal normal 600 15px / var(--line-height) var(--font);
  --typography-pb3: normal normal 600 14px / var(--line-height) var(--font);
  --typography-pb4: normal normal 600 13px / var(--line-height) var(--font);
  --typography-pb5: normal normal 600 12px / var(--line-height) var(--font);
  --typography-pb6: normal normal 600 11px / var(--line-height) var(--font);
  --typography-pb7: normal normal 600 10px / var(--line-height) var(--font);

  --typography-pn1: normal normal 500 16px / var(--line-height) var(--font);
  --typography-pn2: normal normal 500 15px / var(--line-height) var(--font);
  --typography-pn3: normal normal 500 14px / var(--line-height) var(--font);
  --typography-pn4: normal normal 500 13px / var(--line-height) var(--font);
  --typography-pn5: normal normal 500 12px / var(--line-height) var(--font);
  --typography-pn6: normal normal 500 11px / var(--line-height) var(--font);
  --typography-pn7: normal normal 500 10px / var(--line-height) var(--font);

  --typography-pl1: normal normal 400 16px / var(--line-height) var(--font);
  --typography-pl2: normal normal 400 15px / var(--line-height) var(--font);
  --typography-pl3: normal normal 400 14px / var(--line-height) var(--font);
  --typography-pl4: normal normal 400 13px / var(--line-height) var(--font);
  --typography-pl5: normal normal 400 12px / var(--line-height) var(--font);
  --typography-pl6: normal normal 400 11px / var(--line-height) var(--font);
  --typography-pl7: normal normal 400 10px / var(--line-height) var(--font);
}

*,
::after,
::before {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

label {
  display: inline-block;
}

body {
  margin: 0;
  background-color: var(--primary-gray-20);
}

h1 {
  font: var(--typography-h4);
  color: var(--secondary-gray-4);
  padding: 15px 0px;
}

h6 {
  font: var(--typography-pb4);
  font-weight: 700;
  color: var(--primary-fuchsia-50);
}

p {
  font: var(--typography-pl3);
  color: var(--primary-gray-75);
}
