.formio-component-multiple .choices[data-type="select-multiple"] .selection {
  display: block;
  min-height: 50px;
  width: 100%;
  background-color: var(--primary-white);
  border: 1px solid var(--primary-gray-15);
  border-radius: 6px;
  padding: 2px 43px 2px 16.5px;
  cursor: text;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection::after {
  position: absolute;
  content: " ";
  background-image: url("images/components/chevron-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 14px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  right: 0;
  top: calc(50% - 20px);
  border-radius: 50%;
}

.formio-component-multiple
  .choices[data-type="select-multiple"].is-open
  .selection::after {
  transform: rotate(-180deg);
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection
  .choices__input {
  display: none;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection
  .choices__list {
  display: inline;
  cursor: default;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection
  .choices__list
  .choices__item {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--primary-gray-50);
  border-radius: 4px;
  font: var(--typography-pn4);
  color: var(--primary-white);
  padding: 9px 12px;
  margin: 3.5px;
  cursor: unset;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection
  .choices__list
  .choices__item
  .choices__button {
  display: inline;
  appearance: none;
  text-indent: -9999px;
  border: 0;
  background-color: var(--primary-white);
  mask-image: url("images/shared/times.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  width: 9px;
  height: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .selection
  .choices__input--cloned {
  display: inline;
  appearance: none;
  border: none;
  font: var(--typography-pl3);
  color: var(--primary-gray-75);
  margin: 11px 0;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .choices__list--dropdown {
  display: none;
  z-index: 100;
  position: absolute;
  width: 100%;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;

  background-color: white;
  border: 1px solid var(--primary-gray-15);
  border-radius: 6px;
  position: absolute;
  transform: translateY(10px);
  padding: 13px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

.formio-component-multiple
  .choices[data-type="select-multiple"].is-flipped
  .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  transform: translateY(-10px);
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .choices__list--dropdown.is-active {
  display: block;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .choices__list--dropdown
  .choices__item {
  padding: 12px 20px;
  font: var(--typography-pl3);
  color: var(--primary-gray-50);
  cursor: pointer;
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .choices__list--dropdown
  .choices__item.is-highlighted {
  background-color: var(--primary-gray-2);
  color: var(--primary-gray-100);
}

.formio-component-multiple
  .choices[data-type="select-multiple"]
  .choices__list--dropdown
  .choices__item.has-no-results {
  cursor: default;
}

.formio-component-multiple .formio-select-autocomplete-input {
  display: none;
}
