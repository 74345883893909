.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: var(--primary-gray-3);
}

.wizard-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-white);
}

.wizard-header-content {
  display: grid;
  gap: 25px 17px;
  grid-template-columns: 18px auto;
  padding: 25px 25px 27px;
  align-items: center;
}

.wizard-back-btn {
  background-color: var(--primary-dark-gray-50);
  mask-image: url('images/shared/chevron-left-1.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  cursor: pointer;
  width: 10px;
  height: 16px;
}

.wizard-title {
  font: var(--typography-h6);
  color: var(--primary-dark-gray-100);
  margin: auto 0;
}

.wizard-details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  font: var(--typography-pl4);
  color: var(--primary-dark-gray-100);
  margin: auto 0;
}

.wizard-details-reward {
  color: var(--primary-orange-70);
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 6px;
}

.wizard-details-reward-icon {
  background-color: var(--primary-orange-70);
  mask-image: url('images/shared/award.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
}

.wizard-details-required-time {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}

.wizard-details-required-time-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/clock-filled.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.wizard-details-allowed-time {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}

.wizard-details-allowed-time-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/bell.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.wizard-details-type {
  text-transform: capitalize;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}

.wizard-details-type-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/clipboard-medical.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.wizard-details-sep-icon {
  display: block;
  background-color: var(--primary-dark-blue-50);
  border-radius: 50%;
  width: 3.5px;
  height: 3.5px;
}

.wizard-counter {
  flex: 0;
  text-align: center;
  font: var(--typography-pb5);
  font-weight: 700;
  color: var(--primary-dark-blue-50);
  margin: auto 0;
  min-width: 100px;
}

.wizard-progress {
  background-color: var(--primary-gray-10);
  height: 5px;
  display: none;
}

.wizard-completed {
  background-color: var(--primary-main);
  height: 5px;
}

.wizard-content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.wizard-page-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.wizard-page-title {
  font: var(--typography-h2);
  color: var(--primary-dark-gray-100);
  display: flex;
  justify-content: space-between;
}

.wizard-page-description {
  font: var(--typography-pl3);
  color: #606b71;
  font-weight: 400;
  font-size: 14px;
}

.wizard-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  padding: 30px 50px 40px;
  background-color: var(--primary-white);
  border-top: 1px solid var(--primary-gray-10);
  box-sizing: border-box;
}

.wizard-footer > .btn {
  font: var(--typography-pb2);
  font-weight: 700;
  border: none;
  box-sizing: border-box;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.wizard-footer > .btn-primary {
  color: var(--primary-white);
  background-color: var(--primary-main);
}

.wizard-footer > .btn-secondary {
  color: var(--primary-main);
  background: unset;
}

.btn-wizard-icon {
  width: 15px;
  height: 15px;
  background-color: var(--primary-white);
  mask-repeat: no-repeat;
  mask-position: center;
}

.btn-wizard-nav-next-icon {
  mask-image: url('images/shared/chevron-right.svg');
}
.btn-wizard-nav-previous-icon {
  mask-image: url('images/shared/chevron-left-2.svg');
  background-color: var(--primary-main);
}

@media screen and (min-width: 700px) {
  .main-container {
    height: unset;
    min-height: 100vh;
  }

  .wizard-header {
    flex-direction: column-reverse;
  }

  .wizard-content {
    flex: 0;
    overflow: unset;
    max-width: 740px;
  }

  .wizard-header-content {
    /* grid-template-columns: 18px 200px auto; */
    grid-template-columns: 18px 400px auto;
  }

  .wizard-details {
    text-align: right;
  }

  .wizard-counter > * {
    display: unset;
  }

  .wizard-footer {
    border: unset;
    background-color: var(--primary-white);
    max-width: 700px;
    margin-top: 20px;
    border-radius: 6px;
  }

  .wizard-details {
    justify-content: flex-end;
  }
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

div[id^='error-list'] {
  display: none;
}

.col-form-label {
  font: var(--typography-h6);
  color: var(--primary-gray-100);
  padding-bottom: 10px;
  padding-top: 10px;
}

.card-header {
  font: var(--typography-h6);
  color: var(--primary-gray-100);
  padding-bottom: 10px;
}

.col-form-label.field-required::after {
  content: '*';
  color: var(--primary-red-75);
}

@media screen and (min-width: 700px) {
  .col-form-label {
    padding-bottom: 25px;
  }
}

.form-text.error {
  display: flex;
  /* margin-top: 15px; */
  gap: 20px;
  font: var(--typography-pn3);
  color: var(--primary-red-75);

  & a {
    display: contents;
  }
}

.has-error {
  font: var(--typography-pn3);
  color: var(--primary-red-75);
  padding-bottom: 5px;
}

.form-text.error::before {
  content: ' ';
  background-color: var(--primary-red-75);
  mask-image: url('configUI/images/icons/info-circle.svg');
  mask-position: top;
  mask-repeat: no-repeat;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.form-text.text-muted {
  font: var(--typography-pn5);
  color: var(--primary-gray-75);
  margin-top: 10px;
}

.btn {
  font: var(--typography-pb2);
  font-weight: 700;
  border: none;
  box-sizing: border-box;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.btn-primary {
  color: var(--primary-white);
  background-color: var(--primary-main);
}

.formio-multi-value-table {
  margin: -5px 0;
}

.formio-multi-value-table td {
  padding: 5px 0;
}

.formio-multi-value-component-column {
  width: 100%;
}

.formio-button-multi-value-remove-row {
  margin-left: 2px;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  background-color: var(--primary-gray-3);
  border: 1px solid var(--primary-gray-15);
  padding: 0;
}

.formio-button-multi-value-remove-row-icon {
  background-color: var(--primary-gray-50);
  mask-image: url('images/shared/times.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  width: 10px;
  height: 10px;
}

.formio-button-add-another {
  margin-top: 10px;
  background-color: var(--primary-white);
  border: 2px solid var(--primary-main);
  color: var(--primary-main);
  display: flex;
  align-items: center;
  gap: 15px;
  height: 48px;
}

.form-check-inline {
  display: inline-flex;
  margin-right: 27px;
  height: 45px;
}

.field-content .form-check-inline {
  margin-right: 32.4px;
}

.form-check-input {
  width: 12px;
  height: 12px;
  padding-left: 28px;
}
label.form-check-label:not(.label-position-right) {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.form-check-label > span {
  margin-top: 0px;
  text-align: left;
}

.field-wrapper > .wrapper {
  margin-right: 0px;
}

.field-wrapper span {
  margin-top: 50px;
  margin-left: -22px;
}

.form-check-input[checked='true'] {
  background-image: url('images/components/radio-circle-checked.svg');
}

.form-check-label {
  font: var(--typography-pn3);
  color: var(--primary-dark-gray-100);
}

.radio-selected > .form-check-label {
  font: var(--typography-pb3);
  color: var(--primary-main);
}

.form-check-label.label-position-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-wizard-nav-previous {
  border: 2px var(--primary-main) solid !important;
}

@media screen and (max-width: 700px) {
  .field-content .form-check-inline {
    margin-right: 0px;
  }
  .formio-button-add-another {
    width: 100%;
    height: 40px;
    background-color: unset;
  }
  .wizard-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .btn-wizard-nav-next {
    width: 120px;
  }
  .btn-wizard-nav-next-only {
    width: 320px !important;
  }
  .btn-wizard-nav-previous {
    width: 120px;
    gap: 12px !important;
    grid-gap: 12px !important;
  }
  .btn-wizard-nav-submit {
    width: 150px;
  }
  .wizard-details {
    display: none;
  }
  .wizard-page-title {
    visibility: hidden;
  }
  .wizard-page-description {
    display: none;
  }
  .wizard-counter {
    visibility: visible;
    margin-top: -29px;
  }
  .wizard-progress {
    display: block !important;
  }
  .field-label .col-form-label {
    margin-left: 0px;
  }
  .form-check-inline {
    display: inline-block;
    margin-right: 5px;
    border: 1px solid gray;
    margin-bottom: 7px;
    border-radius: 8px;
    background-color: white;
    width: 100%;
  }
  .form-check-inline.radio-selected {
    border: 1.5px solid var(--primary-main);
  }
  .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 12px;
    margin-left: 12px;
  }
  .form-check-label > span {
    /* margin-top: -22px;
    margin-left: 40px; */
  }
  .form-check-label.label-position-right {
    display: block;
    width: 340px;
  }
  .form-check-label.label-position-bottom {
    width: 340px;
  }
  .field-content .form-radio {
    width: 100%;
    margin-left: 0px;
  }
  .card-body .form-text.error {
    margin-left: 0px;
  }
  .btn-wizard-nav-cancel {
    margin-left: 2px;
  }
  .wizard-page-header {
    padding: 0px;
  }
}

.formio-button-add-another-icon {
  background-color: var(--primary-main5);
  mask-image: url('images/profile/plus.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.form-control {
  outline: none;
  border: none;
  font: var(--typography-pl3);
}

.form-control:not(.input-group > .form-control):not(.choices__input),
.input-group {
  display: block;
  min-height: 50px;
  width: 100%;

  background-color: var(--primary-white);
  border: 1px solid var(--primary-gray-15);
  border-radius: 6px;
  padding: 0 20px;
}

.form-control.is-invalid {
  border: 1px solid var(--primary-red-75) !important;
}

.form-control.selection.dropdown {
  cursor: pointer;
}

.input-group {
  display: flex;
  padding-right: 0;
  align-items: center;
}

.input-group > .form-control {
  height: 100%;
  flex: 1;
}

.input-group-append {
  flex: 0 0 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-question-circle::before {
  content: ' ';
  display: inline-block;
  vertical-align: text-top;
  background-color: var(--primary-gray-75);
  mask-image: url('images/icons/info-circle.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  width: 18px;
  height: 18px;
}

.tippy-box {
  position: relative;
  background-color: var(--primary-main);
  font: var(--typography-pn4);
  color: var(--primary-white);
  border-radius: 4px;
  transition-property: transform, visibility, opacity;
}

.tippy-content {
  position: relative;
  z-index: 1;
  padding: 5px 9px;
}

.tippy-box > .tippy-arrow {
  width: 16px;
  height: 16px;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: 0;
}

.tippy-box > .tippy-arrow::before {
  content: ' ';
  color: var(--primary-main);
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-box[data-placement^='right'] > .tippy-arrow::before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center-right;
}

.fa-calendar {
  display: block;
  background-color: var(--primary-gray-75);
  mask-image: url('images/shared/calendar-alt.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  width: 18px;
  height: 18px;
  opacity: 80%;
}

.formio-component-file .fileSelector {
  position: relative;
  padding: 15px;
  border: 2px dashed #ddd;
  text-align: center;
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #446e9b;
  --bs-list-group-active-border-color: #446e9b;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0 var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}

.list-group-item.list-group-header {
  display: none;
}

.editgrid-listgroup > .list-group-item.list-group-header {
  font-size: 14px;
  font-weight: 500;
  display: block;

  /* @media screen and (max-width: 599px) {
    overflow: scroll;
  } */
}

/* .editgrid-listgroup > .list-group-item.list-group-item {
  @media screen and (max-width: 599px) {
    overflow: scroll;
  }
} */

ul.editgrid-listgroup.list-group {
  overflow-x: auto; /* Enable horizontal scrolling for the <ul> */
}

ul.editgrid-listgroup.list-group li {
  min-width: 0;
}

.editgrid-listgroup > .list-group-item div {
  align-items: center;
}

.editgrid-listgroup > li {
  border: solid 1px #e5e5e5;
}

.editgrid-listgroup.list-group .list-group-item .row .custom-header {
  font: var(--typography-pb3);
}

.editgrid-listgroup.list-group .list-group-item .row .btn-group {
  @media screen and (min-width: 700px) {
    justify-content: center;
  }
  flex-direction: row;
  gap: 15px;
}

.editgrid-listgroup.list-group .list-group-item .row .btn-group .btn {
  height: 20px;
  width: 20px;
  padding: 0;
  background-color: transparent;
}

.editgrid-listgroup.list-group .list-group-item .row .btn-group .btn i {
  display: block;
}

.editgrid-listgroup.list-group .list-group-item .row .btn-group .btn::before {
  content: none;
}

.editgrid-listgroup.list-group
  .list-group-item
  .row
  .btn-group
  .btn.editRow
  i.fa.fa-edit::before {
  height: 20px;
  width: 20px;
  display: block;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 10;
  content: url('images/shared/pen-1.svg');
}

.editgrid-listgroup.list-group
  .list-group-item
  .row
  .btn-group
  .btn.removeRow
  i.fa.fa-trash::before {
  height: 20px;
  width: 20px;
  display: block;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 10;
  content: url('images/shared/trash-1.svg');
}

.list-group-striped {
  border: 0.4px solid #696969 !important;
  border-radius: 8px;
  width: 584px;
  margin-left: 8px;
}

.physician--info-dropdown
  .choices[data-type='select-one']
  .selection
  .choices__list
  .choices__item
  span {
  margin-left: 0px !important;
  color: var(--primary-gray-100) !important;
  font: var(--typography-p13) !important;
}

.fileSelector {
  border: 1.2px solid #696969 !important;
  margin-top: -1px;
  color: transparent;
  border-radius: 8px;
  padding-top: 0px !important;
  height: 105px !important;
}

.fileSelector:after {
  content: 'The maximum file size should be 10Mb';
  font: var(--typography-pb2);
  font-weight: 400;
  margin-left: 80px;
  font-size: 12px;
  color: #7c7c7c;
}

.fileSelector::before {
  content: url('images/shared/file-picker.svg');
  position: relative;
  left: -150px;
  top: 30px;
}

.fa.fa-cloud-upload {
  margin-top: -20px;
}

.fa.fa-cloud-upload::before {
  content: '        Select file          ';
  font: var(--typography-pb2);
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  margin-left: -60px;
  z-index: 100;
  background: transparent;
  width: 300px;
  color: #444444;
  top: 0;
}

.browse {
  position: absolute;
  display: flex;
  color: white;
  z-index: 10000;
  width: 600px;
  height: 92px;
  margin-left: -16px;
  margin-top: -55px;
  font-size: 0;
}

.fa.fa-remove {
  height: 13px;
  width: 13px;
  display: block;
  background-repeat: no-repeat;
  color: #d9d9d9;
  margin-left: 520px;
  margin-top: 20px;
  cursor: pointer;
  z-index: 10;
  content: url('images/shared/x-gray.svg');
}

.fileName .fa.fa-remove {
  margin-top: -10px;
  margin-left: 473px;
}

.file .row .fileName {
  font-weight: 500;
  font-size: 16px;
  font-family: Inter;
  padding: 0px;
  margin-top: 20px;
  margin-left: 85px;
  position: absolute;
  color: black;
}

.file .row .fileSize {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  margin-top: 35px;
  margin-left: 75px !important;
  color: #7c7c7c;
}

.fileName::before {
  content: url('images/shared/uploaded-image.svg');
  position: absolute;
  left: -30px;
  top: -21px;
  padding-left: 10px;
  padding-right: 533px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -62px;
  border: 1px solid black;
  border-radius: 8px;
}

.file .row .col-sm-12 {
  margin-top: -10px;
  position: absolute;
  margin-left: -20px;
  padding-bottom: 50px;
  color: var(--primary-red-75);
}

.file .row .col-sm-12::before {
  display: block;
  position: absolute;
  margin-top: 18px;
  margin-right: 100px !important;
  height: 15px;
  width: 15px;
  content: url('images/icons/info-circle.svg');
}

.file-type {
  display: none;
}

.card-body {
  margin-left: -50px;
  width: 700px;
}

.col-md-7 {
  font-weight: 500;
  font-size: 16px;
  font-family: Inter;
  margin-top: -16px;
  min-width: 500px;
  margin-left: -50px;
  position: absolute;
}

.list-group-item .row .col-md-2 {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  margin-top: -15px;
  margin-left: 40px;
  color: #7c7c7c;
}

.col-md-7::before {
  content: url('images/shared/uploaded-image.svg');
  position: relative;
  left: -20px;
  top: 23px;
}

.formio-component-label-hidden {
  position: relative;
}

.formio-select-autocomplete-input,
.choices__input,
.choices__button {
  display: none;
}

.wizard-page {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    padding: 0 20px;
  }
}

.instructions-page-header {
  font: var(--typography-pb4);
  color: var(--primary-main);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.instructions-page-title {
  font: var(--typography-h4);
  color: var(--secondary-gray-4);
}

.meta-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  border-radius: 5px;
  padding: 15px 30px;
  margin: 20px 0px;
  color: var(--primary-gray-100);
  background-color: var(--primary-gray-20);
  font: var(--typography-pb1);
}

.meta-data.fit-content {
  width: fit-content;
}

.meta-data-title {
  font: var(--typography-pl5);
  color: var(--primary-gray-75);
  text-transform: none !important;
}

.meta-data-value {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.meta-data-type {
  text-transform: capitalize;
}

.meta-data-reward-points {
  color: var(--primary-main);
}

.meta-data-type-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/clipboard-medical.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.meta-data-required-time-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/clock-filled.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.meta-data-allowed-time-icon {
  background-color: var(--primary-gray-40);
  mask-image: url('images/activity/types/bell.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 18px;
  height: 18px;
}

.meta-data-reward-icon {
  background-color: var(--primary-fuchsia-75);
  mask-image: url('images/shared/points.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 16px;
  height: 16px;
}

.table {
  width: 100%;
  gap: 20px;
  border-spacing: 0;
}

.formio-hidden {
  display: none !important;
}

.wizard-page > .formio-component:not(.row) {
  margin: 25px 20px;
}

/* .wizard-page > .formio-component-physician {
  @media screen and (max-width: 599px) {
    width: max-content;
  }
} */

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  margin-bottom: 5px;
  overflow-x: auto;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
}

.editRow::before {
  content: 'Edit';
}
.editRow {
  font-size: 12px;
  height: 30px;
  padding: 0 10px;
}
.editRow i {
  display: none;
}

.removeRow::before {
  content: 'Remove';
}
.removeRow {
  font-size: 12px;
  height: 30px;
  padding: 0 10px;
}
.removeRow i {
  display: none;
}

@media screen and (min-width: 700px) {
  .formio-component:not(.formio-component-submit):not(.row) {
    background-color: var(--primary-white);
    padding: 30px 30px 20px 30px !important;
    border-radius: 6px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.04) !important;
  }

  .wizard-page > .formio-component:not(.row) {
    margin: 10px 20px;
  }

  .row {
    padding: 0px 10px;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding: 10px 10px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }
}

@media (max-width: 700px) {
  .card-body {
    margin-left: 0px;
    width: 100%;
  }
  .list-group-striped {
    width: 100%;
    margin-left: 0px;
  }
  /* This is to override last empty space which is parent of fileType selector that is marked display=none above, this only applies when image is uploaded successfully */
  .list-group-striped .col-md-2:last-child {
    display: none;
  }
  .formio-component-file .col-md-1 {
    display: flex;
    justify-content: flex-end;
  }
  .formio-component-file .fa.fa-remove {
    position: absolute;
    top: 32px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .formio-component-file .col-md-7 {
    position: absolute;
    min-width: 100%;
    margin-left: -8px;
    top: 10px;
    padding: 10px;
  }

  .formio-component-file .col-md-2 {
    padding: 10px;
  }

  .list-group-item .row .col-md-2 {
    margin-top: -5px;
  }

  .browse {
    width: 100%;
  }

  .fileSelector::before {
    left: -60px;
    top: 24px;
  }

  .col-md-7::before {
    top: 16px;
  }

  .file .row .fileSize {
    margin-top: 20px;
    padding-bottom: 14px;
  }

  /* Below is to handle upload pdf / zip file  preview */
  .formio-component-file .file .row:first-child {
    border: 1px solid black;
    border-radius: 8px;
  }

  .formio-component-file .file .fileName::before {
    padding: 10px calc(100% - 60px) 10px 10px;
    border: 0px;
    top: -12px;
  }
  .formio-component-file .file .fileName .fa.fa-remove {
    margin-left: calc(100% - 140px);
    top: 11px;
  }
}
.sr-only {
  display: none;
}

textarea {
  height: 160px !important;
  overflow-y: scroll !important;
}

@media screen and (max-width: 599px) {
  .wizard-page > .formio-component:not(.row) {
    margin-bottom: 10px !important;
  }
}
