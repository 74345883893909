.formio-component-select .choices[data-type="select-one"] .selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background-color: var(--primary-white);
  border: 1px solid var(--primary-gray-15);
  border-radius: 6px;
  padding: 0 43px 0 20px;
  cursor: pointer;
}

.formio-component-select .choices[data-type="select-one"] .selection::after {
  position: absolute;
  content: " ";
  background-image: url("images/components/chevron-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px 14px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  right: 0;
  top: calc(50% - 20px);
  border-radius: 50%;
}

.formio-component-select
  .choices[data-type="select-one"]
  .selection
  .choices__list
  .choices__item {
  font: var(--typography-pl3);
  color: var(--primary-gray-75);
}

.formio-component-select .choices[data-type="select-one"] .selection .choices__list .choices__item .choices__button {
  align-self: center;
  display: inline;
  background-image: url("images/shared/x-gray.svg");
  background-repeat: no-repeat;
  background-color: white;
  background-size: 10px 12px;
  width: 12px;
  height: 12px;
  top: 2px;
  border: none;
  margin-left: 13px;
  text-indent: -9999px;
  cursor: pointer;
}

.formio-component-select .choices[data-type="select-one"] .selection .choices__list .choices__item span {
  font-size: 12px;
  margin-left: -15px;
  @media screen and (max-width: 700px) {
    top: 0px;
    font-size: 14px;
  }
}

.formio-component-select
  .choices[data-type="select-one"].is-open
  .selection::after {
  transform: rotate(-180deg);
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown {
  display: none;
  z-index: 100;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;
  word-break: break-all;
  will-change: visibility;
  background-color: white;
  border: 1px solid var(--primary-gray-15);
  border-radius: 6px;
  position: absolute;
  transform: translateY(10px);
  padding: 13px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__input--cloned {
  display: inline;
  appearance: none;
  border: none;
  font: var(--typography-pl3);
  color: var(--primary-gray-75);
  margin: 0px 0px 10px 18px;
}

.formio-component-select
  .choices[data-type="select-one"].is-flipped
  .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  transform: translateY(-10px);
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown.is-active {
  display: block;
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__item {
  padding: 12px 20px;
  font: var(--typography-pl3);
  color: var(--primary-gray-50);
  cursor: pointer;
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__item.is-selected {
  color: var(--primary-dark-blue-70);
  padding: 8px 20px;
  margin: 0; 
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__item.is-selected::after {
  height: 8px;
  width: 14px;
  content: " ";
  border: 2.5px solid var(--primary-dark-blue-70);
  border-top: none;
  border-right: none;
  border-radius: 0;
  transform: rotate(-45deg);
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__item.is-highlighted {
  background-color: var(--primary-gray-2);
  color: var(--primary-gray-100);
}

.formio-component-select
  .choices[data-type="select-one"]
  .choices__list--dropdown
  .choices__item.has-no-results {
  cursor: default;
}

.formio-component-select .formio-select-autocomplete-input {
  display: none;
}
